'use client'

import SearchPopup from '@homeSearch/modules/searchPopup'
import useProgressRouter from '@hooks/useProgressRouter'
import { commercialDataLayer } from '@plugin/google/gtm/modules/home'
import { RecentViewManager } from '@plugin/recentView'
import getHouseRoute from '@plugin/searchRoute/modules/house'
import { cacheRecentActivity } from '@plugin/user'
import { houseList } from '@router'
import { houseSearchType } from '@routerConfig'
import { first, get } from 'lodash-es'
import { Suspense, useCallback, useEffect, useState } from 'react'
import useLayoutContext from '../layout/context'
import { SearchWrap } from '../modules/base'
import useHomeSearchContext, { cacheSuggestedSuburbs } from '../modules/context'
import { fetchCommercial } from '../modules/fetch'
import HomeSearchFilter from '../modules/filter'
import { COMMERCIAL_FILTER } from '../modules/filter/config'
import { HomeSearchInputNew } from '../modules/input'
import Location from '../modules/location'
import HomeSearchPopup from '../modules/popup'

export default function CommercialContent({
  title = 'Discover Commercial'
}) {

  /**
   * 这里在input和filter外面的单独处理了 suburbs和surrounding
   * input在点击search按钮的时候，同步suburbs和surrounding到filter
   * filter在点击关闭的时候，同步数据到input
   */

  const { suburbs, surrounding, displayFilter, showFilter, hideFilter, changeSuburbs, toggleSurrounding } = useLayoutContext()
  const { pushRouter } = useProgressRouter()

  // input search同步数据到filter
  const filterHandle = useCallback(({ suburbs, surrounding }) => {
    changeSuburbs(suburbs)
    toggleSurrounding(surrounding)
    showFilter()
  }, [showFilter, changeSuburbs, toggleSurrounding])

  // show properties点击事件
  const confrimHandle = useCallback(({
    suburbs,
    surrounding,
    searchType,
    routerQuery,
    priceFrom,
    priceTo,
    bedroom,
    bathroom,
    carspaces,
    categories,
    landarea,
    floorarea
  }) => {

    const houseRoute = getHouseRoute()
    houseRoute.setSuburbs(suburbs)
    houseRoute.surround = surrounding
    houseRoute['price-from'] = priceFrom
    houseRoute['price-to'] = priceTo
    houseRoute.bedroom = bedroom
    houseRoute.bathroom = bathroom
    houseRoute.carspaces = carspaces
    houseRoute.categories = categories
    houseRoute.landarea = landarea
    houseRoute.floorarea = floorarea
    houseRoute.pushNewZealand()
    const slug = houseRoute.toSlug()
    const st = searchType || first(COMMERCIAL_FILTER.searchTypes)
    const route = houseList(get(st, 'route'), slug, { filter: routerQuery })

    const cacheData = {
      suburbs,
      surrounding,
      priceFrom,
      priceTo,
      bedrooms: bedroom,
      bathrooms: bathroom,
      carspaces,
      categories,
      landarea,
      floorarea,
      filter: routerQuery,
    }
    if (st.route === houseSearchType.commercialForSale) {
      RecentViewManager.cacheCommercialForSaleBySuburb(cacheData)
    } else if (st.route === houseSearchType.commercialForLease) {
      RecentViewManager.cacheCommercialForLeaseBySuburb(cacheData)
    }



    cacheRecentActivity({ searchType: st, route, suburbs, slug, filter: routerQuery })
    commercialDataLayer.searchClick(st)
    pushRouter(route)
    cacheSuggestedSuburbs(suburbs)
  }, [pushRouter])

  return (
    <>
      <SearchWrap title={title} sub='Search our extensive commercial real estate across New Zealand including office space, retail, industrial & more.'>
        <ResidentialSearchContent onSearch={confrimHandle} onFilter={filterHandle} suburbs={suburbs} surrounding={surrounding}></ResidentialSearchContent>
      </SearchWrap>
      <HomeSearchFilter
        show={displayFilter}
        suburbs={suburbs}
        surrounding={surrounding}
        setSuburbs={changeSuburbs}
        toggleSurrounding={toggleSurrounding}
        onClose={hideFilter}
        onConfirm={confrimHandle}
        filter={COMMERCIAL_FILTER}
        fetcher={fetchCommercial}
      ></HomeSearchFilter>
    </>
  )
}


export function ResidentialSearchContent({
  onSearch,
  onFilter,
  suburbs,
  surrounding
}) {

  const { setSuburbs, toggleSurrounding } = useHomeSearchContext()
  useEffect(() => setSuburbs(suburbs), [suburbs, setSuburbs])
  useEffect(() => toggleSurrounding(surrounding), [surrounding, toggleSurrounding])

  const [showInputPop, setShowInputPop] = useState(false)
  return (
    <Suspense>
      {
        !showInputPop && (
          <HomeSearchInputNew
            filterButton
            onSearch={onSearch}
            onFilter={onFilter}
            mobileDisableInput
            onInputClick={() => setShowInputPop(true)}
          >
            <HomeSearchPopup fetcher={fetchCommercial}></HomeSearchPopup>
          </HomeSearchInputNew>
        )
      }
      <Location></Location>
      <SearchPopup
        show={showInputPop}
        onClose={() => setShowInputPop(false)}
        onFilter={onFilter}
        fetcher={fetchCommercial}
        onSearch={onSearch}
      />
    </Suspense>
  )
}
